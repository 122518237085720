body {
  margin: 0;
  padding: 0;
  font:
    14px "Montserrat"
}

.ShoppingCartButtonDiv {
  margin-left: auto;
  margin-right: 10px;
  width: 40px;
}

.ShoppingCartButton {
  background: hsla(0, 100%, 100%, 0.8);
  transition: background 200ms ease-in-out;
}

.ShoppingCartButton:hover {
  background-color: yellow !important;
}

.inline-block-child {
  display: inline-block;
}

.th-left {
  text-align: left !important;
}

.psm_background {
  width: 100vw;
  max-width: 100vw;
}

.psm_wrap {
  /*position: fixed;*/
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 479px) {
  .psm_wrap {
    width: 100% !important;
    max-width: 100vw !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    height: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}


.psm_box {
  /*position: fixed;*/
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  max-height: calc(100% - 75px);
  top: 0;
  bottom: 0;
  margin-top: 75px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 479px) {
  .psm_box {
    width: 100% !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.psm_header {
  background-color: #fff;
}

.psm_footer {
  background-color: #fff;
}

.psm_content {
  flex: 1;

  /*border-top: 1px solid #ddd;   Removed so breadcrumbs could be on top of content*/
  /* overflow-y: auto; removed due to scrolling issues on mobile*/
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button-no-underline {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

/*.item_listing_frame {
  
}*/

.item_listing_box {
  width: 340px;
  height: 340px;
  background: white;
  border: 1px solid transparent;
}

/*@media only screen and (max-width: 479px) {
  .item_listing_box{
    width: 163.5px !important;
    height: 284px !important;
  }
}*/

.item_listing_box:hover {
  border: 1px solid #d0d0d0;
}

.item_listing_image_box {
  width: 100%;
}

.item_listing_image {
  display: block;
  height: 182px;
  margin-left: auto;
  margin-right: auto;
}

/*@media only screen and (max-width: 479px) {
  .item_listing_image{
      width: 80%;
      height: 182px;
    }
}*/

.item_listing_text {
  width: 80%;
  top: 226px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.item_listing_text_desc {
  width: 90%;
  top: 226px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.item_listing_price {
  width: 80%;
  height: Hug (20px);
  top: 290px;
  gap: 12px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.textfields {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  background-color: white;
  background: white;
}

.textfields_label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
}

.search_textfields {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  max-width: 340px;
  height: 40px;
  background-color: white;
  background: white;
}

.search_textfields_label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  max-width: 340px;
  height: 40px;
}

.next_delivery_on {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.hub_select {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.psm_background {
  background-color: #fff;
  width: 100vw;
  max-width: 100vw;
}

.filters_box {
  width: 265px;
  background: white;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-left: 10px;
  margin-right: 0px;
  margin-top: 20px;
  min-height: 700px;
  min-width: 265px;
}

.store_box {
  width: 100%;
  max-width: 1165px;
}

.store-top {
  width: 98% !important;
  margin-left: auto;
  margin-right: auto;
}

.main_store_box {
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin-left: 0px;
  margin-right: auto;
}

.filters_clearall {
  color: #58b55d;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.filters_label {
  color: #1e2832;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.filters_text {
  color: #1e2832;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.filters_header {
  color: #1e2832;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 900;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.filters_treeitem {
  color: #1e2832;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.filters_radio {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0px;
  gap: 0px;
  line-height: 0px;
  margin: 0px;
}

.primary_green {
  color: #58b55d;
}

.primary_radio {
  line-height: 0px;
  margin: 0px;
}

.slick-slide img {
  width: 100%;
}

@media only screen and (max-width: 479px) {
  .mobile-hide {
    display: none !important;
  }
}

.image-carousel {
  padding: 30px !important;
}

.search_grid_items {
  padding: 10px;
  width: 360px;
}

.product_grid_items {
  padding: 10px;
}

.filters_button {
  margin-top: 28px;
}

.filters_close_button {
  float: right;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

@media only screen and (max-width: 479px) {
  .item_image_slider_wrapper {
    margin-top: 60px !important;
    width: 300px !important;
  }
}

.item_image_slider_wrapper {
  margin-top: 20px !important;
  margin-left: 10px !important;
  width: 700px;
}

.item_header_text {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}

.item_price_text {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
}

.item_details_text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
}

.item_details_text_quantity_label {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
}

.item_right_side_block {
  margin-top: 120px;
}

.component_box {
  background: white;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 24px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.center-text {
  text-align: center;
}

.deliveryinstructions_textfield {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  max-width: 356px;
  background-color: white;
  background: white;
  height: 100px;
}

.short-divider {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.checkout_info_label {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.checkout_info_label_bold {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.checkout_info_value {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
}

.checkout_info_value_bold {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
}

.order_complete_order_id {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #58b55d;
}

.order_complete_h1 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.order_complete_text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.white_button {
  border-color: #58b55d;
  color: "black";
  border-width: 2;
  text-transform: "none";
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.cc-text-bold {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.cc-text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.invoice_card_values {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
}

.invoice_card_values_bold {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
}

.invoice_card_values_bold_red {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  color: red;
}


.invoice_card_label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
}

.invoice_card_invnums {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: #58b55d
}

.invoice_card_values_green {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #58b55d;
}

.arrow {
  position: absolute;
  color: #000000;
  font-size: 2.5em;
  font-weight: bold;

  cursor: pointer;
}

.nextArrow {
  top: 150px;
  right: -25px;
}

.prevArrow {
  top: 150px;
  left: -25px;
}